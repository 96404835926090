/* Wordpress classes */
.aligncenter:not(img),
.alignfull,
.alignwide {
  @apply w-full block text-center h-auto;
}

img.aligncenter,
.aligncenter img {
  @apply block mx-auto w-auto;
}

.alignleft {
  @apply md:float-left md:mr-10 w-auto;
}

.alignright {
  @apply md:float-right md:ml-10 w-auto;
}

.responsive-iframe {
  @apply aspect-w-16 aspect-h-9 mb-6 w-full;
}

.not-prose-wp {
  @apply !m-0 !mt-8
}

.text-large-wp {
  @apply text-xl lg:text-2xl xl:text-3xl
}

.state-list-grid {
  @apply grid grid-cols-2 grid-flow-col grid-rows-28 md:grid-cols-4 md:grid-rows-14
}