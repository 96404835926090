@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@leafwell/components/dist/styles';

/*! purgecss start ignore */
@import './prose';
@import './menu';
@import './rich-title';
@import './wordpress';
@import './team-grid.css';

@layer base {
  b,
  strong {
    @apply font-medium;
  }
}

@media screen and (prefers-reduced-motion: no-preference) {
  html {
    -webkit-overflow-scrolling: touch;
  }
}

/*
* Offset anchors from sticky header
*/
:root {
  --dashboard-form-height: 0px;
  --header-height: 160px;
}

* {
  scroll-margin-top: calc(
    var(--header-height) + var(--dashboard-form-height) + 1.5rem
  );
}

#__next {
  @apply flex flex-col min-h-screen;
}

/*
* Hide scrollbar visually
*/
.hide-scrollbar {
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/*
* Disable drag in elements
*/
.no-drag {
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
}

/*
* workaround for tailwind class
* decoration-green and
* decoration-offset-4
*/
.line-simple {
  text-decoration-color: #36dc90;
  text-underline-offset: 4px;
}

.marquee + .rounded-section {
  @apply !pt-0;
}

.marquee + .rounded-section figure {
  @apply !-mt-52;
}

.partner-state-select + img {
  @apply !w-4;
}

.leafwell-author-profile + .rounded-section figure {
  @apply !-mt-36;
}

.leafwell-post-blocks > section {
  @apply !p-0 mt-8;
}

.leafwell-sizzle-block-right {
  right: 1.25rem;
}

.leafwell-sizzle-block-left {
  left: 1.25rem;
}

@media (min-width: 1024px) {
  .leafwell-sizzle-block-right {
    right: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .leafwell-sizzle-block-left {
    left: 2.5rem;
  }
}

@media (min-width: 1440px) {
  .leafwell-sizzle-block-right {
    --yA: calc(100vw - 1440px);
    --yB: calc(var(--yA) / 2);
    right: calc(var(--yB) + 2.5rem);
  }
}

@media (min-width: 1440px) {
  .leafwell-sizzle-block-left {
    --yA: calc(100vw - 1440px);
    --yB: calc(var(--yA) / 2);
    left: calc(var(--yB) + 2.5rem);
  }
}

/*
* Page Block Table of Contents custom styles
* 
*/

.table-contents-btn:before,
.table-contents-btn:after {
  @apply absolute bg-white duration-300;
  content: '';
  left: -15px;
  z-index: -1;
  width: 36px;
  height: 22px;
  border-radius: 1em/0.5em;
}

.table-contents-btn:before {
  top: -17px;
  border: 1px solid rgb(156 163 175);
  border-bottom: 0;
  transform: rotate(40deg);
}

.table-contents-btn:after {
  bottom: -17px;
  border: 1px solid rgb(156 163 175);
  border-top: 0;
  transform: rotate(-40deg);
}

.table-contents-list::after {
  content: '';
  @apply absolute bg-white;
  right: 0px;
  top: calc(50% - 20px);
  height: 64px;
  border-right: 1px solid #fff;
}

@media (min-width: 1024px) {
  .table-contents-btn:hover::before,
  .table-contents-btn:hover::after {
    @apply bg-gray-100;
  }

  .table-contents-btn:before,
  .table-contents-btn:after {
    @apply absolute bg-white duration-300;
    left: -20px;
    width: 52px;
    height: 30px;
    border-radius: 1em/0.5em;
  }

  .table-contents-btn:before {
    top: -24px;
  }

  .table-contents-btn:after {
    bottom: -24px;
  }

  .table-contents-list::after {
    top: calc(50% - 31px);
    height: 110px;
  }
}

/*
* workaround for tailwind class
* border-e-lilac-100
*/

@media (min-width: 768px) {
  .border-e-lilac-100 {
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: white;
    border-inline-end-color: rgb(237 233 254 / var(--tw-border-opacity));
  }
}

.leafwell-purple-scrollbar::-webkit-scrollbar {
  height: 2px;
}

.leafwell-purple-scrollbar::-webkit-scrollbar-track {
  background: #dee0de;
}

.leafwell-purple-scrollbar::-webkit-scrollbar-thumb {
  background: #7b62a7;
}

.leafwell-purple-scrollbar-vertical::-webkit-scrollbar {
  width: 2px;
}

.leafwell-purple-scrollbar-vertical::-webkit-scrollbar-track {
  background: #dee0de;
}

.leafwell-purple-scrollbar-vertical::-webkit-scrollbar-thumb {
  background: #7b62a7;
}

@media (min-width: 1024px) {
  .leafwell-webinar-bg,
  .leafwell-vpd-bg {
    background-image: url(/pentagon-white-bg.svg);
    background-repeat: no-repeat;
    background-size: 53% 100%;
  }

  .leafwell-webinar-bg {
    background-size: 55% 100%;
  }

  article.leafwell-add-margin-top-to-li > ol > li:not(:first-child) {
    margin-top: 2rem !important;
  }
}

article.leafwell-add-margin-top-to-li > ol > li:not(:first-child) {
  margin-top: 1rem !important;
}

.drop-in {
  animation: drop-in 1s ease 200ms backwards;
}

@keyframes drop-in {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translate(0px);
  }
}

@media (max-width: 1023px) {
  .leafwell-mc-bg {
    z-index: 1;
  }
  .leafwell-mc-bg::before {
    content: '';
    position: absolute;
    top: -4rem;
    left: -1.25rem;
    z-index: -1;
    width: 100vw;
    height: calc(100% + 5rem);
    background-color: #fff;
  }
  .leafwell-mc-bg--affiliate::before {
    top: -4rem;
    height: calc(100% + 5rem);
  }
  .leafwell-mc-bg::after {
    content: '';
    position: absolute;
    top: calc(100% + 1rem);
    left: -1.25rem;
    width: 100vw;
    border-top: 70px solid #fff;
    border-left: 50vw solid transparent;
    border-right: 50vw solid transparent;
  }
}

.lw-text-section p {
  margin-bottom: 0.25em;
}

.hero-about ~ .rounded-section figure {
  @apply !block mt-0 mb-0 self-center justify-self-center order-first md:order-last
}

.hero-about ~ .rounded-section h3,
.hero-about ~ .rounded-section article {
  @apply text-center md:text-left 
}

.hero-about ~ .list-cards header {
  @apply justify-items-center
}

#budtender-hubspotForm iframe {
  @apply lg:!w-[700px]
}

.mask-clamped-text {
  position: relative;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0));
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0));
}

.bg-purple-gradient {
  background: linear-gradient(180deg, #7B62A7 58%, #654F8B 100%);
}

.bg-dark-gray-gradient {
  background: linear-gradient(180deg, #475B69 48%, #32424E 100%);
  ;
}

.bg-dark-green-gradient {
  background: linear-gradient(180deg, #239C65 0%, #239863 100%);
}

.box-shadow-gray-300 {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.box-shadow-light-green {
  box-shadow: 0px 10px 70px 0px #00000014;
}

.text-shadow-step-number {
  text-shadow: 4px 4px 0px #CEB9F3;
}




/*! purgecss end ignore */
